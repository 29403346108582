<template>
  <PageLayout :hideFooter="true">
    <div class="has-padding">
      <transition name="fade" mode="out-in">
        <ui-loader key="loading" v-if="!appStartupLoadReady" />
        <div v-else-if="appStartupLoadReady && isLoggedIn" class="columns">
          <div
            class="column is-one-third-touch is-one-quarter-desktop is-hidden-mobile"
          >
            <div class="card profile-card">
              <div class="card-content">
                <div class="title is-3 has-text-centered">
                  {{ $t("Views.Locations.Card_Profile_Welcome") }}
                </div>
                <div class="has-text-centered">
                  <ProfileImage
                    class="is-128x128 has-margin-bottom"
                    :image="profile.Image"
                    :id="profile.Id"
                    :size="150"
                  />
                </div>
                <div
                  class="title is-4 has-text-centered"
                  v-html="profile.FullName"
                ></div>

                <div v-if="profile.Tags.length" class="has-text-centered">
                  <div class="title is-5">
                    {{ $t("Views.Locations.Title_Tags") }}
                  </div>
                  <div class="tags is-block has-text-centered">
                    <div>
                      <span
                        v-for="(tag, index) in profile.Tags.split(',')"
                        :key="index"
                        v-html="tag"
                        class="tag is-cyan"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>

              <footer class="card-footer">
                <a
                  @click="logOff"
                  class="card-footer-item"
                  v-text="
                    $t('Components.MainSiteElements.LoggedInProfile.LogOff')
                  "
                ></a>
              </footer>
            </div>
          </div>
          <div class="column">
            <p
              class="title"
              v-text="$t('Frontend.Views.Account.Dashboard.Title_Channels')"
            ></p>

            <table
              v-if="channelsPageState.channels"
              class="table is-fullwidth is-striped"
            >
              <thead>
                <tr>
                  <th
                    v-text="
                      $t('Frontend.Views.Account.Dashboard.TableHeader_Name')
                    "
                  ></th>
                  <th
                    class="has-text-centered"
                    width="100"
                    v-text="
                      $t(
                        'Frontend.Views.Account.Dashboard.TableHeader_Locations'
                      )
                    "
                  ></th>
                  <th
                    class="has-text-centered"
                    width="100"
                    v-text="
                      $t(
                        'Frontend.Views.Account.Dashboard.TableHeader_Reservations'
                      )
                    "
                  ></th>
                  <th
                    class="has-text-centered"
                    width="100"
                    v-text="
                      $t('Frontend.Views.Account.Dashboard.TableHeader_Bookers')
                    "
                  ></th>
                </tr>
              </thead>
              <tbody v-if="channelsPageState.channels.length > 0">
                <tr
                  v-for="(channel, index) in channelsPageState.channels"
                  :key="index"
                >
                  <td>
                    <a @click="selectChannel(channel)">{{ channel.Name }}</a>
                  </td>
                  <td class="has-text-centered">{{ channel.NrOfLocations }}</td>
                  <td class="has-text-centered">
                    {{ channel.NrOfReservations }}
                  </td>
                  <td class="has-text-centered">{{ channel.NrOfBookers }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </transition>
    </div>
  </PageLayout>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import channelProvider from "@/providers/channel";

const PageLayout = () =>
  import(
    /* webpackChunkName: "acount" */ "@/components/MainSiteElements/Frontend/PageLayout"
  );
const ProfileImage = () =>
  import(/* webpackChunkName: "acount" */ "@/components/UI/ProfileImage");

export default {
  name: "account-dashboard",
  components: {
    ProfileImage,
    PageLayout,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState(["appStartupLoadReady"]),
    ...mapState("channelStore", ["channelsPageState"]),
    ...mapGetters({
      isLoggedIn: "profileStore/isLoggedIn",
      profile: "profileStore/getProfile",
      profileState: "profileStore/getProfileState",
    }),
  },

  beforeCreate() {},

  watch: {
    appStartupLoadReady() {
      this.notLoggedInAction();
    },
  },

  created() {
    this.notLoggedInAction();
  },

  methods: {
    ...mapMutations("channelStore", ["setChannel", "setChannelsPageState"]),
    notLoggedInAction() {
      if (this.appStartupLoadReady && !this.isLoggedIn) {
        this.$nextTick(() => this.$router.push({ name: "home" }));
        return;
      }
      if (this.appStartupLoadReady && this.isLoggedIn) {
        this.getProfileChannels();
      }
    },

    getProfileChannels() {
      let self = this;
      self.isLoading = true;

      let channelPageState = this.$objectHelper.cleanSource(
        this.channelsPageState
      );

      channelProvider.methods
        .getChannelsProfileHasAccessTo()
        .then((response) => {
          if (response.status === 200) {
            channelPageState.channels = response.data;
            this.setChannelsPageState(channelPageState);
          }
        })
        .catch((e) => {
          ////console.log(error.response)
        })
        .finally(() => {
          self.isLoading = false;
        });
    },

    selectChannel(channel) {
      if (channel) {
        this.setChannel(channel);

        this.$router.push({
          name: `channelStart`,
          params: { channelId: channel.Id },
        });
      }
    },

    logOff() {
      this.$store.dispatch("profileStore/logoff");
    },
  },
};
</script>

<style lang="scss" scoped>
.column-left {
  width: 300px;

  .row-messages {
    a {
      align-items: center;
    }
  }
}
</style>
