var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageLayout',{attrs:{"hideFooter":true}},[_c('div',{staticClass:"has-padding"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.appStartupLoadReady)?_c('ui-loader',{key:"loading"}):(_vm.appStartupLoadReady && _vm.isLoggedIn)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-one-third-touch is-one-quarter-desktop is-hidden-mobile"},[_c('div',{staticClass:"card profile-card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"title is-3 has-text-centered"},[_vm._v(" "+_vm._s(_vm.$t("Views.Locations.Card_Profile_Welcome"))+" ")]),_c('div',{staticClass:"has-text-centered"},[_c('ProfileImage',{staticClass:"is-128x128 has-margin-bottom",attrs:{"image":_vm.profile.Image,"id":_vm.profile.Id,"size":150}})],1),_c('div',{staticClass:"title is-4 has-text-centered",domProps:{"innerHTML":_vm._s(_vm.profile.FullName)}}),(_vm.profile.Tags.length)?_c('div',{staticClass:"has-text-centered"},[_c('div',{staticClass:"title is-5"},[_vm._v(" "+_vm._s(_vm.$t("Views.Locations.Title_Tags"))+" ")]),_c('div',{staticClass:"tags is-block has-text-centered"},[_c('div',_vm._l((_vm.profile.Tags.split(',')),function(tag,index){return _c('span',{key:index,staticClass:"tag is-cyan",domProps:{"innerHTML":_vm._s(tag)}})}),0)])]):_vm._e()]),_c('footer',{staticClass:"card-footer"},[_c('a',{staticClass:"card-footer-item",domProps:{"textContent":_vm._s(
                  _vm.$t('Components.MainSiteElements.LoggedInProfile.LogOff')
                )},on:{"click":_vm.logOff}})])])]),_c('div',{staticClass:"column"},[_c('p',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$t('Frontend.Views.Account.Dashboard.Title_Channels'))}}),(_vm.channelsPageState.channels)?_c('table',{staticClass:"table is-fullwidth is-striped"},[_c('thead',[_c('tr',[_c('th',{domProps:{"textContent":_vm._s(
                    _vm.$t('Frontend.Views.Account.Dashboard.TableHeader_Name')
                  )}}),_c('th',{staticClass:"has-text-centered",attrs:{"width":"100"},domProps:{"textContent":_vm._s(
                    _vm.$t(
                      'Frontend.Views.Account.Dashboard.TableHeader_Locations'
                    )
                  )}}),_c('th',{staticClass:"has-text-centered",attrs:{"width":"100"},domProps:{"textContent":_vm._s(
                    _vm.$t(
                      'Frontend.Views.Account.Dashboard.TableHeader_Reservations'
                    )
                  )}}),_c('th',{staticClass:"has-text-centered",attrs:{"width":"100"},domProps:{"textContent":_vm._s(
                    _vm.$t('Frontend.Views.Account.Dashboard.TableHeader_Bookers')
                  )}})])]),(_vm.channelsPageState.channels.length > 0)?_c('tbody',_vm._l((_vm.channelsPageState.channels),function(channel,index){return _c('tr',{key:index},[_c('td',[_c('a',{on:{"click":function($event){return _vm.selectChannel(channel)}}},[_vm._v(_vm._s(channel.Name))])]),_c('td',{staticClass:"has-text-centered"},[_vm._v(_vm._s(channel.NrOfLocations))]),_c('td',{staticClass:"has-text-centered"},[_vm._v(" "+_vm._s(channel.NrOfReservations)+" ")]),_c('td',{staticClass:"has-text-centered"},[_vm._v(_vm._s(channel.NrOfBookers))])])}),0):_vm._e()]):_vm._e()])]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }